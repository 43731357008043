<template>
  <div class="about">
    <b-container>
        <b-row class="py-5">
          <b-col cols="12" lg="6" class="mb-5 mb-lg-0">
            <img src="../assets/about.png" alt="About Graphic" class="mb-4 w-75">
            <h1>Who is Debtsettlement.org?</h1>
            <h2 class="text-primary">Learn About us</h2>
            <p class="mt-4 text-left">
              <router-link to="/">DebtSettlement.org</router-link>
              provides consumers struggling with debt the education to understand their options and
              access to trusted solution providers. Our team of experienced debt specialists have over 50 years of
              experience helping millions of people resolve their debt situation.

              <br><br><span class="font-weight-bold">Our Promise</span><br>
              We are committed to providing the best education and access to free counseling services to help those
              struggling with debt. If you have any feedback, suggestions or ideas to help us provide the best service,
              we would love to hear from you. <br><router-link to="/feedback" class="btn btn-primary mt-3 d-block mx-auto ml-lg-0 ">Submit Feedback</router-link>
            </p>
          </b-col>

          <b-col cols="12" lg="6">
            <GetStarted class="mx-auto"/>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
import GetStarted from '@/components/GetStarted.vue'

export default {
  components: {
    GetStarted
  }
}
</script>


<style lang="scss">

.about {
.get-started-widget {
  max-width: 400px !important;
}
}

</style>
